import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'; // Import all solid icons
import { Chart } from 'chart.js/auto';

// Add all icons to the library so you can use them in your components
library.add(fas);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon); // Register FontAwesomeIcon component globally

app.config.globalProperties.$Chart = Chart;

app.use(router).mount('#app');
