<template>
    <header>
        <nav>
            <div class = "logo">
              <router-link to="/">
                <img src = "../../assets/All/business logo.png">
              </router-link>
            </div>
            <div class = "hamburger" @click="toggleMenu">
              <font-awesome-icon :icon="['fas', 'bars']" size="xl" />
            </div>
            <ul class = "nav-menu">
                <li class = "x-bar" @click="toggleXbar">
                  <font-awesome-icon :icon="['fas', 'x']" size="lg"/>
                </li>
                <li class = "logoSmall">
                  <img src = "../../assets/All/business logo.png">
                </li>
                <li class = "list-items">
                  <router-link to="/about" @click="handleNavClick">About</router-link>
                </li>
                <li class = "list-items">
                  <router-link to="/coaching" @click="handleNavClick">Coaching</router-link>
                </li>
                <li class = "list-items">
                  <router-link to="/speaking" @click="handleNavClick">Speaking</router-link>
                </li>
                <li class = "list-items">
                  <router-link to="/tools" @click="handleNavClick">Tools</router-link>
                </li>
                <li class = "list-items">
                  <router-link to="/contact" @click="handleNavClick">Contact</router-link>
                </li>
                <li class = "list-items">
                  <router-link to="faq" @click="handleNavClick">FAQ</router-link>
                </li>
            </ul>
        </nav>
    </header>
    
  <!-- <font-awesome-icon :icon="['fas', 'bars']" /> -->
</template>

<script>
export default {
  methods: {
    toggleMenu(){
      var menuTest = document.querySelector('.nav-menu');
      var xBar = document.querySelector('.x-bar');
      menuTest.style.display = 'flex';
      menuTest.style.backgroundColor = 'rgb(' + 0 + ',' + 0 + ',' + 0 + ')';
      xBar.style.display = 'block';
      console.log(menuTest);
    },
    toggleXbar(){
      var xBar = document.querySelector('.x-bar');
      var navMenu = document.querySelector('.nav-menu');
      xBar.style.display = 'none';
      navMenu.style.display = 'none';
    },
    hideMenu(){
      var viewportWidth = window.innerWidth;
      if(viewportWidth < 768){
        var hideMenu = document.querySelector('.nav-menu');
        hideMenu.style.display = 'none';
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleNavClick() {
      this.scrollToTop();
      this.hideMenu();
    }
  }
}
</script>

<style>
header{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: black;
}
.logo{
  width: 150px;
  height: 50px;
}
.logo img{
  width: 100%;
  height: 100%;
}
nav {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;
  height: 70px;
}
.nav-menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 18px;
}
.list-items {
  margin-right: 20px;
  padding: 10px;
}
.list-items a {
  color: white;
  text-decoration: none;
}
.x-bar{
  display: none;
}
.hamburger{
  display: none;
}
.logoSmall{
  display: none;
}
@media(max-width: 768px){
    .nav-menu{
        display: none;
        position: fixed;
        top: 0px;
        left: 0px;
        /*background-color: rgba(79, 184, 89, 0.1);*/
        /*background-color: rgba(255, 255, 255, 0.1);*/
        /*background-color: rgba(0, 0, 0);*/
        /* backdrop-filter: blur(20px); */
        /* background-color: rgba(105, 105, 105, 0.3); */
        width: 100%;
        height: 100vh;
        /*display: flex;*/
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all 0.3s;
    }
    .list-items{
      /* margin-left: 20px; */
      font-size: 20px;
      padding: 20px;
    }
    .list-items a{
      color: rgb(79, 184, 89);
    }
    .logoSmall{
      display: block;
      width: 150px;
      height: 50px;
      margin-bottom: 50px;
    }
    .logoSmall img{
      width: 100%;
      height: 100%;
    }
    .hamburger{
      display: block;
    }
    .x-bar{
      /*display: block;*/
      position: fixed;
      top: 30px;
      right: 25px;
    }
}

</style>