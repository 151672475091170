<template>
  <Header />
  <!-- <nav>
    <router-link to="/">Home</router-link>
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
  <Footer />
  
</template>

<script>


import Header from './components/All/Header.vue'
import Footer from './components/All/Footer.vue'

export default{
  components: {Header, Footer}
}
</script>


<style>
*{
  margin: 0;
  padding: 0;
}
* {
    box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}
body{
  /*background-color: #181818;*/
  /*background: #01110a;*/
  background: black;
  height: 100%;
  min-height: 100vh;
  /*background-color: #011307;*/
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}

/* nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
