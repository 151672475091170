import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/coaching',
    name: 'coaching',
    component: () => import(/* webpackChunkName: "coaching" */ '../views/Coaching.vue')
  },
  {
    path: '/speaking',
    name: 'speaking',
    component: () => import(/* webpackChunkName: "coaching" */ '../views/Speaking.vue')
  },
  {
    path: '/tools',
    name: 'tools',
    component: () => import(/* webpackChunkName: "coaching" */ '../views/Tools.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "coaching" */ '../views/Contact.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "coaching" */ '../views/FAQ.vue')
  },
  {
    path: '/budget',
    name: 'budget',
    component: () => import(/* webpackChunkName: "coaching" */ '../views/Budget.vue')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
