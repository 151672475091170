<template>
    <div class = "footer-container">
        <div class = "footer-wrapper">
            <div class = "image-container">
                <div class = "footer-logo">
                    <img src = "../../assets/All/football logo.png">
                </div>
            </div>
            <div class = "footer-info-container">
                <div class = "footer-info-wrapper">
                    <!-- <div class = "consultation-link">
                        <p>Acheving your goals one play at a time. 
                            <a class = "lets-go-link" href = "">Let's Go!</a>
                        </p>
                    </div> -->
                    <div class = "contact-email-container">
                        <h3>Contact Email</h3>
                        <a class = "footer-links" href="#">hello@moneymatthew.com</a>
                    </div>
                    <div class = "navigate-list">
                        <h3>Navigate</h3>
                        <div class = "navigate-items">
                            <a class = "navigate-footer-item footer-links" href="#">Home</a>
                            <router-link class = "navigate-footer-item footer-links" to="/about" @click="handleNavClick">About</router-link>
                            <router-link class = "navigate-footer-item footer-links" to="/tools" @click="handleNavClick">Tools</router-link>
                            <router-link class = "navigate-footer-item footer-links" to="/contact" @click="handleNavClick">Contact</router-link>
                            <router-link class = "navigate-footer-item footer-links" to="/faq" @click="handleNavClick">FAQ</router-link>
                            <!-- <a class = "navigate-footer-item footer-links" href="#">About</a>
                            <a class = "navigate-footer-item footer-links" href="#">Tools</a>
                            <a class = "navigate-footer-item footer-links" href="#">Contact</a>
                            <a class = "navigate-footer-item footer-links" href="#">FAQ</a> -->
                        </div>
                    </div>
                    <div class = "playbook-list">
                        <h3>Playbooks</h3>
                        <div class = "playbook-items">
                            <router-link class = "navigate-footer-item footer-links" to="/coaching" @click="handleNavClick">Financial Coaching</router-link>
                            <router-link class = "navigate-footer-item footer-links" to="/speaking" @click="handleNavClick">Speaking</router-link>
                            <!-- <a class = "playbook-footer-item footer-links" href="#">Financial Coaching</a>
                            <a class = "playbook-footer-item footer-links" href="#">Speaking</a> -->
                        </div>
                    </div>
                    <!-- <div>
                        <font-awesome-icon :icon="['fab', 'facebook']" />
                    </div> -->
                </div>
            </div>
        </div>
        <div class = "privacy-container" style = "border-top: 1px solid rgba(255, 255, 255, 0.3); ">
            <div style = "padding-top: 20px; padding-bottom: 20px;">
                <a href="#">Privacy Notice & Disclaimer</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        toggleMenu(){
        var menuTest = document.querySelector('.nav-menu');
        var xBar = document.querySelector('.x-bar');
        menuTest.style.display = 'flex';
        menuTest.style.backgroundColor = 'rgb(' + 0 + ',' + 0 + ',' + 0 + ')';
        xBar.style.display = 'block';
        console.log(menuTest);
        },
        toggleXbar(){
        var xBar = document.querySelector('.x-bar');
        var navMenu = document.querySelector('.nav-menu');
        xBar.style.display = 'none';
        navMenu.style.display = 'none';
        },
        hideMenu(){
        var viewportWidth = window.innerWidth;
        if(viewportWidth < 768){
            var hideMenu = document.querySelector('.nav-menu');
            hideMenu.style.display = 'none';
        }
        },
        scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        handleNavClick() {
        this.scrollToTop();
        this.hideMenu();
        }
  }
}
</script>

<style>
@media(max-width: 700px){
.footer-container{
    padding-top: 50px;
    border-top: 1px solid rgb(150, 150, 150);
}
.footer-wrapper{
    display: flex;
    flex-direction: column;
}
.image-container{
    display: flex;
    justify-content: center;
}
.footer-info-container{
    margin-top: 60px;
}
.footer-info-wrapper{
    padding-left: 20px;
    padding-right: 20px;
}
.consultation-link{
    color: white;
}
.consultation-link{
    line-height: 30px;
    font-size: 25px;
}
.footer-logo{
  width: 120px;
  height: 80px;
}
.footer-logo img{
  width: 100%;
  height: 100%;
}
.contact-email-container{
    margin-top: 20px;
    color: white;
}
.contact-email-container h3{
    margin-bottom: 20px;
}
.navigate-list{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: white;
}
.navigate-list h3{
    margin-bottom: 20px;
}
.navigate-items{
    display: flex;
    flex-direction: column;
}
.navigate-footer-item{
    margin-top: 10px;
}
.playbook-list{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: white;
}
.playbook-list h3{
    margin-bottom: 20px;
}
.playbook-items{
    display: flex;
    flex-direction: column;
}
.playbook-footer-item{
    margin-top: 10px;
}
.privacy-container{
    margin-top: 50px;
    text-align: center;
}
.privacy-container a{
    color: white;
    text-decoration: none;
}
.lets-go-link{
    color: white;
}
.footer-links{
    text-decoration: none;
    color: rgb(150, 150, 150);
}
}
@media(min-width: 700px) and (max-width: 1000px){
.footer-container{
    padding-top: 50px;
    border-top: 1px solid rgb(150, 150, 150);
}
.footer-wrapper{
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}
.image-container{
    display: flex;
    justify-content: center;
}
.footer-info-container{
    margin-top: 60px;
}
.footer-info-wrapper{
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.consultation-link{
    color: white;
}
.consultation-link{
    line-height: 30px;
    font-size: 25px;
}
.footer-logo{
  width: 120px;
  height: 80px;
}
.footer-logo img{
  width: 100%;
  height: 100%;
}
.contact-email-container{
    margin-top: 20px;
    color: white;
}
.contact-email-container h3{
    margin-bottom: 20px;
}
.navigate-list{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: white;
}
.navigate-list h3{
    margin-bottom: 20px;
}
.navigate-items{
    display: flex;
    flex-direction: column;
}
.navigate-footer-item{
    margin-top: 10px;
}
.playbook-list{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: white;
}
.playbook-list h3{
    margin-bottom: 20px;
}
.playbook-items{
    display: flex;
    flex-direction: column;
}
.playbook-footer-item{
    margin-top: 10px;
}
.privacy-container{
    margin-top: 50px;
    text-align: center;
}
.privacy-container a{
    color: white;
    text-decoration: none;
}
.lets-go-link{
    color: white;
}
.footer-links{
    text-decoration: none;
    color: rgb(150, 150, 150);
}
}
@media(min-width: 1000px) and (max-width: 1300px){
.footer-container{
    padding-top: 50px;
    border-top: 1px solid rgb(150, 150, 150);
}
.footer-wrapper{
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
}
.image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 30%;
    max-width: 30%;
}
.footer-info-container{
    margin-top: 60px;
    flex: 0 0 70%;
    max-width: 70%;
}
.footer-info-wrapper{
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.consultation-link{
    color: white;
}
.consultation-link{
    line-height: 30px;
    font-size: 25px;
}
.footer-logo{
  width: 120px;
  height: 80px;
}
.footer-logo img{
  width: 100%;
  height: 100%;
}
.contact-email-container{
    margin-top: 20px;
    color: white;
}
.contact-email-container h3{
    margin-bottom: 20px;
}
.navigate-list{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: white;
}
.navigate-list h3{
    margin-bottom: 20px;
}
.navigate-items{
    display: flex;
    flex-direction: column;
}
.navigate-footer-item{
    margin-top: 10px;
}
.playbook-list{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: white;
}
.playbook-list h3{
    margin-bottom: 20px;
}
.playbook-items{
    display: flex;
    flex-direction: column;
}
.playbook-footer-item{
    margin-top: 10px;
}
.privacy-container{
    margin-top: 50px;
    text-align: center;
}
.privacy-container a{
    color: white;
    text-decoration: none;
}
.lets-go-link{
    color: white;
}
.footer-links{
    text-decoration: none;
    color: rgb(150, 150, 150);
}
}
@media(min-width: 1300px) and (max-width: 1600px){
.footer-container{
    padding-top: 50px;
    border-top: 1px solid rgb(150, 150, 150);
}
.footer-wrapper{
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    padding-left: 80px;
    padding-right: 80px;
}
.image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 30%;
    max-width: 30%;
}
.footer-info-container{
    margin-top: 60px;
    flex: 0 0 70%;
    max-width: 70%;
}
.footer-info-wrapper{
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.consultation-link{
    color: white;
}
.consultation-link{
    line-height: 30px;
    font-size: 25px;
}
.footer-logo{
  width: 120px;
  height: 80px;
}
.footer-logo img{
  width: 100%;
  height: 100%;
}
.contact-email-container{
    margin-top: 20px;
    color: white;
}
.contact-email-container h3{
    margin-bottom: 20px;
}
.navigate-list{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: white;
}
.navigate-list h3{
    margin-bottom: 20px;
}
.navigate-items{
    display: flex;
    flex-direction: column;
}
.navigate-footer-item{
    margin-top: 10px;
}
.playbook-list{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: white;
}
.playbook-list h3{
    margin-bottom: 20px;
}
.playbook-items{
    display: flex;
    flex-direction: column;
}
.playbook-footer-item{
    margin-top: 10px;
}
.privacy-container{
    margin-top: 50px;
    text-align: center;
}
.privacy-container a{
    color: white;
    text-decoration: none;
}
.lets-go-link{
    color: white;
}
.footer-links{
    text-decoration: none;
    color: rgb(150, 150, 150);
}
}
@media(min-width: 1600px){
.footer-container{
    padding-top: 50px;
    border-top: 1px solid rgb(150, 150, 150);
}
.footer-wrapper{
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    padding-left: 80px;
    padding-right: 80px;
}
.image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 30%;
    max-width: 30%;
}
.footer-info-container{
    margin-top: 60px;
    flex: 0 0 70%;
    max-width: 70%;
}
.footer-info-wrapper{
    padding-left: 40px;
    padding-right: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.consultation-link{
    color: white;
}
.consultation-link{
    line-height: 30px;
    font-size: 25px;
}
.footer-logo{
  width: 120px;
  height: 80px;
}
.footer-logo img{
  width: 100%;
  height: 100%;
}
.contact-email-container{
    margin-top: 20px;
    color: white;
}
.contact-email-container h3{
    margin-bottom: 20px;
}
.navigate-list{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: white;
}
.navigate-list h3{
    margin-bottom: 20px;
}
.navigate-items{
    display: flex;
    flex-direction: column;
}
.navigate-footer-item{
    margin-top: 10px;
}
.playbook-list{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: white;
}
.playbook-list h3{
    margin-bottom: 20px;
}
.playbook-items{
    display: flex;
    flex-direction: column;
}
.playbook-footer-item{
    margin-top: 10px;
}
.privacy-container{
    margin-top: 50px;
    text-align: center;
}
.privacy-container a{
    color: white;
    text-decoration: none;
}
.lets-go-link{
    color: white;
}
.footer-links{
    text-decoration: none;
    color: rgb(150, 150, 150);
}
}
</style>